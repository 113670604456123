import { graphql } from 'gatsby'
import React, { useState, useEffect } from 'react'
import PageAnimation from '../components/animation/page-animation'
import Layout from '../components/app-layout'
import LightBox from '../components/light-box'
import Seo from '../components/seo'
import GlobalStyle from '../helpers/global-styles'

const AmenitiesLightbox = ({ data }) => {
  const [index, setIndex] = useState(0)
  const [width, setWidth] = useState(0)
  const [xPosition, setXPosition] = useState(0)
  const [sortedImages, setSortedImages] = useState([])

  const imageId = typeof window !== "undefined" ? window.sessionStorage.getItem('imageId') : null
  const dataName = typeof window !== "undefined" ? window.sessionStorage.getItem('dataName') : null
  const images = data[dataName]?.edges || []

  const handleClick = () => {
    setIndex(index)
  }

  const handleClickPrev = () => {
    if (index === 0) return
    setIndex(index - 1)
    setXPosition(xPosition + width)
  }
  const handleClicknext = () => {
    if (index === images.length - 1) {
      setIndex(0)
      setXPosition(0)
    } else {
      setIndex(index + 1)
      setXPosition(xPosition - width)
    }
  }

  useEffect(() => {
    if (imageId) {
      const sorted = images.sort((a, b) => {
        return a.node.name.localeCompare(b.node.name, undefined, {numeric: true});
      });
      const firstImage = sorted.find(el => el.node.id === imageId)
      const rest = sorted.filter(el => el.node.id !== firstImage.node.id)
      setSortedImages([firstImage, ...rest])
    }
    return () => typeof window !== "undefined" ? window.sessionStorage.removeItem('imageId') : null
  }, [imageId])

  return <Layout>
    <PageAnimation>
      <GlobalStyle />
      <LightBox
        images={sortedImages}
        setWidth={setWidth}
        xPosition={xPosition}
        handleClickPrev={handleClickPrev}
        handleClicknext={handleClicknext}
        handleClick={handleClick}
        setXPosition={setXPosition}
      />
    </PageAnimation>
  </Layout>
}

export default AmenitiesLightbox

export const query = graphql`
{
  lobby: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/lobby" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  clubLounge: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/club-lounge" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  library: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/library" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  floorCourtyard: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/floor-courtyard" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  fitnessUpper: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/fitness-upper" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  fitnessLower: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/fitness-lower" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  roofRestourant: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/roof-restourant" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  pool: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/pool" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  rooftopLounge: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/rooftop-lounge" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  cam: allFile(
    filter: {
      extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      relativeDirectory: { eq: "amenities/cam" }
    }
  ) {
    edges {
      node {
        name
        id
        childImageSharp {
          fluid(maxWidth: 2048, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`

export const Head = () => <Seo title="Amenities lightbox" />
